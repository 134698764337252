.App {
  text-align: center;
}

.page-max {
  max-width: 1280px;
  margin: 0 auto;
}

.dark {
  background-color: #1b1c21;
  width: 100vw;
}

.title {
  font-size: 96px;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 90px;
}

.Toastify_toast-theme--dark {
  color: #d8669e;
}

.Toastify_toast-theme--colored.Toastify__toast--error {
  color: #d8669e;
}

@media screen and (max-width: 1024px) {
  .page-max {
    max-width: 100%;
  }
}

@media screen and (max-width: 565px) {
  .title {
    font-size: 64px;
  }
}
