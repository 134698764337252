nav {
  width: 100%;
}

div.nav {
  display: flex;
  justify-content: space-between;
  padding: 25px 80px;
  height: 77px;
  font-weight: 700;
}

.nav-right {
  width: 350px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 996px) {
  div.nav {
    padding: 25px 40px;
  }
}

@media screen and (max-width: 700px) {
  .nav-right {
    flex-direction: column;
  }
}

@media screen and (max-width: 526px) {
  div.nav {
    padding: 25px 15px;
  }
}
