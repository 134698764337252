header.contact-header {
  text-align: left;
  margin: 65px 0 20px 0;
  width: 85%;
}

.contact {
  width: 85%;
  margin: 0 auto;
}

.contact-container {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 40px 0 120px 0;
  text-align: left;
}

.contact-left {
  width: 60%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-helper-text {
  text-align: left;
  font-size: 14px;
  color: #bdc0c7;
  line-height: 28px;
}

.email-image {
  height: 50px;
  max-width: 80%;
  background-image: url("../../assets/hello@allaboatesgoudreau.com.svg");
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-weight: bold;
}

.social-helper {
  text-transform: uppercase;
  color: #bdc0c7;
  font-size: 12px;
}

.social-logos {
  height: 70px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 150px;
}

.social-link:hover {
  border: none;
}

.contact-right {
  width: 50%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10%;
}

input {
  margin: 10px 0;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #bdc0c7;
  width: 349px;
  padding-left: 16px;
}

textarea {
  border-radius: 4px;
  margin: 10px 0;
  padding: 12px 0 0 16px;
  font-family: "Inter", sans-serif;
  border: 1px solid #bdc0c7;
  width: 349px;
  resize: none;
}

.send-btn {
  background-color: #d8669e;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px;
  width: 178px;
  height: 43px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .contact-container {
    width: 85%;
    flex-direction: column;
  }
  .contact-left {
    width: 100%;
  }

  .contact-form {
    padding-left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .contact-left {
    height: 400px;
  }

  .contact-form {
    margin-top: 20px;
  }
}
