.about-screen-container {
  margin: 0 auto;
  width: 90%;
  padding: 65px 0 120px 0;
  display: flex;
  justify-content: space-between;
}

.about-header-left {
  text-align: left;
  width: 80%;
  margin-right: 80px;
}

.about-text {
  font-size: 14px;
  font-weight: 400;
  color: #bdc0c7;
  line-height: 28px;
  letter-spacing: 0.01em;
  margin: 40px 0 0 0;
  width: 100%;
  padding-right: 10px;
}

.about-header-right {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.about-main-image {
  height: 80%;
  background-image: url("../../assets/507D8CC1-40C1-4675-8C8B-7AC3304E567C_1_105_c.jpeg");
  background-size: cover;
  background-position: center bottom;
  margin-bottom: 5%;
}

.about-bottom-image-group {
  display: flex;
  justify-content: space-between;
  height: 30%;
}

.bottom-image {
  height: auto;
  width: 30%;
  background-size: cover;
  background-position: center bottom;
}

.one {
  background-image: url("../../assets/584D7595-54BE-455A-9BEC-B38CB0FDB6C3_1_105_c.jpeg");
}

.two {
  background-image: url("../../assets/56923716-1D46-4B16-80D5-088658DB5F56_1_105_c.jpeg");
}

.three {
  background-image: url("../../assets/6A301039-0407-4072-B2F2-1264D8E7FA2D_1_105_c.jpeg");
  background-position: center top;
}

@media screen and (max-width: 1218px) {
  .about-screen-container {
    flex-direction: column;
    width: 80%;
    height: auto;
  }

  .about-header-left {
    margin-right: 0;
    width: 100%;
    margin-bottom: 60px;
  }

  .about-header-right {
    height: 500px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .about-main-image {
    height: 500px;
    width: 70%;
  }

  .about-bottom-image-group {
    flex-direction: column;
    height: 100%;
    width: 27%;
  }

  .bottom-image {
    width: 100%;
    height: 150px;
  }
}

@media screen and (max-width: 700px) {
  .about-header-right {
    /* height: 500px;
    width: 100%; */
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }

  .about-main-image {
    width: 100%;
    height: 300px;
  }

  .about-bottom-image-group {
    /* flex-direction: row; */
    width: 100%;
    height: 950px;
    justify-content: space-between;
  }

  .bottom-image {
    width: 100%;
    height: 300px;
    background-position: center;
  }

  .three {
    background-position: center top;
  }
}

@media screen and (max-width: 565px) {
  h2.title {
    font-size: 64px;
  }
}
