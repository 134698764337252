.footer {
  display: flex;
  justify-content: space-between;
  height: 273px;
  padding: 80px 80px 69px 80px;
}

.footer-left {
  width: 60%;
  text-align: left;
}

.footer-name {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.footer-email {
  background-image: url("../../assets/footer_hello@allaboatesgoudreau.com.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  margin-top: 16px;
}

.footer-socials {
  display: flex;
  justify-content: space-between;
  width: 250px;
  height: 28px;
}

.copyright-text {
  color: #838ba0;
  font-size: 10px;
  line-height: 14.52px;
  margin-top: 54px;
  text-align: left;
}

.licenses {
  text-align: left;
  margin-top: 22px;
}

.license-link {
  font-size: 12px;
  font-weight: 700;
  color: #cdced6;
  text-transform: uppercase;
  line-height: 14.52px;
  padding-bottom: 0;
  text-decoration: underline;
  letter-spacing: 8%;
}

.footer-left-link {
  padding-right: 8px;
}

.footer-right-link {
  border-left: 1px solid #494c5a;
  padding-left: 8px;
}

.footer-left-link:hover,
.footer-right-link:hover {
  border: none;
}

.footer-right-link:hover {
  border-left: 1px solid #494c5a;
}

@media screen and (max-width: 996px) {
  .footer {
    padding: 40px;
  }
}

@media screen and (max-width: 725px) {
  .footer {
    flex-direction: column;
    padding: 40px 0;
    margin: 0 auto;
    width: 85%;
  }

  .footer-right {
    margin-top: 20px;
  }
}

@media screen and (max-width: 526px) {
  .footer {
    width: 90%;
  }
}
