.project-card {
  width: 48%;
  min-width: 250px;
  height: auto;
  margin: 30px 0;
}

img.project-img {
  max-width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}

.project-title {
  font-size: 28px;
  font-weight: 700;
  margin-top: 24px;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.project-link-group {
  display: flex;
}

.project-link {
  text-decoration: underline;
  align-items: flex-start;
  font-weight: 400;
}

.separator {
  padding: 0 10px;
}

.project-description-container {
  margin: 20px 0 10px 0;
  min-height: 90px;
}

.project-description {
  font-size: 14px;
  line-height: 1.5em;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.language-bubbles {
  background-color: #3c414e;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 12px;
  margin-right: 16px;
  margin-top: 16px;
}

@media screen and (max-width: 996px) {
  .project-card {
    width: 100%;
    margin: 30 auto;
  }
}

@media screen and (max-width: 526px) {
  .project-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .project-title {
    margin-bottom: 20px;
  }
}
