.services-text {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
  padding: 120px 0;
}

.services-title {
  font-size: 32px;
  font-weight: 700;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  margin: 38px 0 38px 0;
  font-weight: 400;
  height: auto;
  letter-spacing: 0.02em;
  color: #cacdd4;
}

.tech-service-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 106px;
  margin: 20px 5px;
}

.tech-stack-img {
  margin-bottom: 10px;
}

@media screen and (max-width: 526px) {
  .services-text {
    width: 90%;
  }
}
