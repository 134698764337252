*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* prevents padding and margin from being added to total height and width */
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252830;
  color: #ffffff;
}

a {
  text-decoration: none;
  color: white;
  padding-bottom: 8px;
}

a:active {
  color: white;
}

a:hover {
  border-bottom: 2px solid #d8669e;
}

.redirect-pink {
  display: flex;
  align-items: center;
  color: #d8669e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.redirect-pink:hover {
  border: none;
}

.dark-grey {
  color: #808593;
  font-weight: 700;
}
